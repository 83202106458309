import RecordingSession from './recording-session/RecordingSession'

function App() {
  return (
    <div className='app'>
      <div>
        <h2>Parkinsons Data Collection</h2>
      </div>
      <RecordingSession/>
    </ div>
  );
}

export default App;
