import './recording-session-navigation.css'

function RecordingSessionNavigation(props) {
    return (
        <div className='recording-session-nav'>
            <div className="recording-session-nav-wrapper">
                <div className='recording-session-nav-item1'>
                    <button onClick={props.GoToPreviousVideo}>&lt;</button>
                </div>
                <div className='recording-session-nav-item2'>
                    <h4>{props.currentVideoIndex+1}/{props.amountOfVideos}</h4>
                </div>
                <div className='recording-session-nav-item3'>
                    <button onClick={props.GoToNextVideo}>&gt;</button>
                </div>
            </div>
        </div>
    )
}

export default RecordingSessionNavigation;