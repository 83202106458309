import './WebcamPlayback.css'
import React, { useState, useRef, useEffect} from 'react'

function WebcamPlayback (props) {

    const DisplayBlob = () => {
        if(props.videoStream == null) {
            return;
        }
        return props.videoStream;
    }
    const videoRef = useRef();
    useEffect(() => {    
        videoRef.current?.load();
    });

    const handleReplay = () => {
        videoRef.current.currentTime = 0
        videoRef.current.play();
        //setIsPlayed(false)
    }

    const handleVideoEnded = () => {
        handleReplay()
    }

    return (
        <div className='webcam-playback'>
            <video 
                ref={videoRef} 
                src={props.videoStream} 
                controls 
                width="500" height="350" 
                autoPlay
                onEnded={handleVideoEnded} 
            />
        </div>
    )
}

export default WebcamPlayback 