import React, { useState, useRef} from 'react'

import './InstructionalVideo.css'

function InstructionalVideo(props) {
    // let isPlaying = false
    const [isPlaying, setIsPlaying] = useState(false)
    const [isPlayed, setIsPlayed] = useState(false)

    const vidRef = useRef(null);

    const handlePlayVideo = () => {
        vidRef.current.play();
    }

    const handlePauseVideo = () => {
        vidRef.current.pause()
        setIsPlaying(false);
    }

    const handleReplay = () => {
        vidRef.current.currentTime = 0
        vidRef.current.play();
        setIsPlayed(false)
    }

    const handleVideoStarted = () => {
        setIsPlaying(true)
    }

    const handleVideoEnded = () => {
        handleReplay()
    }

    const createButtonControls = () => {
        let buttonControls = <button onClick={handlePlayVideo}>Play</button>
        if(isPlaying) {
            buttonControls = <button onClick={handlePauseVideo}>Pause</button>
        }

        if(isPlayed && !isPlaying){
            buttonControls = <>
                <button onClick={handleReplay}>Replay</button>
                <button onClick={props.handleSwitchToRecording}>Record</button>
            </>
        }

        return buttonControls
    }

    return (
        <div>
            <video 
                src={props.videoPath} 
                type="video/mp4" 
                ref={vidRef}
                width="500" height="350" 
                onPlay={handleVideoStarted} 
                onEnded={handleVideoEnded} 
                autoPlay 
                controls
                muted
            />
            {/* {createButtonControls()} */}
        </div>
    )
}

export default InstructionalVideo