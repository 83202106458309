import React from 'react';
import { useRef, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid';

import Timer from '../timer/Timer'

import { saveAs } from 'file-saver';

import WebcamPlayback from '../webcam-playback/WebcamPlayback';

let mediaRecorder = null;
let chunks = [];
let localStream = null;

function WebcamRecorder (props) {
    const videoRef = useRef(null);

    const [isRecording, setIsRecording] = useState(false)
    const [isRecorded, setIsRecorded] = useState(false)

    useEffect(() => {
        setupVideoCapture();
    }, []);

    const setupVideoCapture = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
             navigator.mediaDevices
            .getUserMedia({ video: { width: 500 } })
            .then(stream => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
                var options = {
                    mimeType: 'video/webm'
                 }
                mediaRecorder = new MediaRecorder(stream, options);
                mediaRecorder.ondataavailable = function (ev) {
                    chunks.push(ev.data);
                }

                mediaRecorder.onstop = handleStopRecording;
            })
            .catch(err => {
                console.error("error:", err);
            });

        }
    }
        
    const handleStartRecording = () => {
        setIsRecording(true)
        mediaRecorder.start();       
    }

    const stopRecording = () => {
        mediaRecorder.stop();
    }

    const handleStopRecording = () => {
        setIsRecording(false)
        setIsRecorded(true)
        const blob = new Blob(chunks, {
            type: "video/webm"
        });
        
        props.SaveRecordedVideo(blob)
        chunks = []
    }

    const handleUpload = async () => {
        try {
            const response = await fetch(
                    'https://btwmuhyq3e.execute-api.us-east-1.amazonaws.com/generate-url-for-s3-upload?' + new URLSearchParams({
                        s3Bucket: 'parkinsons-recording-sessions'
                    }),
                    {
                        method: 'get'
                    }
                );
            const responseJSON = await response.json();
            const uploadURL = responseJSON['url']
            
            const blob = new Blob(chunks, {
                type: "video/webm"
            });
            console.log(uploadURL)
            await fetch(
                uploadURL,
                {
                    method: 'put',
                    body: blob,
                }
            ) 
        } catch(e) {
            console.log("THERE WAS AN ERROR")
            console.log(e)
        }
    }

    
    const GenerateRecorderInterface = () => {
        let toReturn = <button onClick={handleStartRecording}>Start Recording</button>
        if(isRecorded) {
            toReturn = <div><button onClick={handleStartRecording}>Redo</button><button onClick={handleUpload}>Upload</button></div>
            return toReturn;
        } 

        if(!isRecording) {
            return <button onClick={handleStartRecording}>Start Recording</button>
        }

        return <Timer onTimerFinished={stopRecording}/>
    }

    return (
        <>
            <div>
                {<video ref={videoRef} width="500" height="350"/>}
            </div>
            <div>
                {GenerateRecorderInterface()}
            </div>
        </>
    )
}

export default  WebcamRecorder