import React, { useState, useEffect, useRef} from 'react'
import { v4 as uuidv4 } from 'uuid';

import InstructionalVideo from '../instructional-video/InstructionalVideo';
import WebcamRecorder from '../webcam-recorder/WebcamRecorder';
import WebcamPlayback from '../webcam-playback/WebcamPlayback';
import RecordingSessionNavigation from '../recording-session-navigation/recording-session-navigation';

import './RecordingSession.css'

function RecordingSession() {
    const [instructionalVideoPaths,setInstructionalVideoPaths] = useState([])
    const [recordedVideos, setRecordedVideos] = useState([])
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
    const [readyForUpload, setReadyForUpload] = useState(false)

    const currentVideoIndexRef = useRef(0);
    currentVideoIndexRef.current = currentVideoIndex

    useEffect(() => {
        const loadInstructionalVideos = async ()=>{
            try {
                const res = await fetch('Videos/instructional-video-metadata.json')
                const data = await res.json()
                let _instructionalVideoPaths = []
                let _recordedVideos = []
                for (let i = 0; i < data.length; i++) {
                    const fileName = data[i]['videoFileName']
                    const filePath = '/Videos/instructions/'+fileName
                    _instructionalVideoPaths[i] = filePath
                    _recordedVideos[i] = 'null'
                }
                setInstructionalVideoPaths(_instructionalVideoPaths)
                setRecordedVideos(_recordedVideos)
            } catch (error) {
                
            }
        }
        loadInstructionalVideos()
    },[])

    useEffect(() => {
        let countOfRecordedVideos = 0
        for(let i = 0; i < recordedVideos.length; i++) {
            if(recordedVideos[i] != 'null') {
                countOfRecordedVideos += 1
            }
        }
        console.log(countOfRecordedVideos, instructionalVideoPaths.length)
        if(countOfRecordedVideos == instructionalVideoPaths.length) {
            setReadyForUpload(true)
        } else {
            setReadyForUpload(false)
        }
    }, [recordedVideos])


    const GoToNextVideo = () => {
        if(currentVideoIndex >= instructionalVideoPaths.length - 1) {
            return;
        }
        setCurrentVideoIndex((prevState)=>{
            //console.log(recordedVideos);
            return prevState+1
            
        })
        //setCurrentVideoIndex(prevState => prevState + 1);
    }

    const GoToPreviousVideo = () => {
        if(currentVideoIndex == 0) {
            return;
        }
        setCurrentVideoIndex(prevState => prevState - 1);
    }

    const SaveRecordedVideo = (video) => {
        setRecordedVideos((prevState) => {
            let _recordedVideos = [...prevState]
            console.log("HERE " + currentVideoIndex)
            _recordedVideos[currentVideoIndexRef.current] = video
            return _recordedVideos
        })
    }

    const DisplayRecorderOrRecorded = () => {
        if(recordedVideos[currentVideoIndex] === 'null' | recordedVideos[currentVideoIndex]==null) {
            return (
                <div className='item2'>
                    <WebcamRecorder 
                        SaveRecordedVideo={SaveRecordedVideo}
                    /> 
                </div>
            )
        } else {
            const url = window.URL.createObjectURL(recordedVideos[currentVideoIndex]);
            return (
                <div className='item3'>
                    <WebcamPlayback videoStream = {url}/>
                </div>
            )
        }
    }

    const DisplayUploadButton = () => {
        if(readyForUpload) {
            return (<div><button onClick={handleUpload}>upload</button></div>)
        } else {
            return
        }
    }

    const handleUpload = async () => {
        try {
            const uuid = uuidv4()
            for(let i=0; i<recordedVideos.length; i++) {
                const blob = recordedVideos[i]
                if(blob==='null') {
                    continue
                }

                const s3FilePath = uuid + '/' + i.toString() 
                const response = await fetch(
                    'https://btwmuhyq3e.execute-api.us-east-1.amazonaws.com/generate-url-for-s3-upload?' + new URLSearchParams({
                        s3Bucket: 'parkinsons-recording-sessions',
                        keyName: s3FilePath
                    }),
                    {
                        method: 'get'
                    }
                );
                const responseJSON = await response.json();
                const uploadURL = responseJSON['url']

                console.log(uploadURL)
                await fetch(
                    uploadURL,
                    {
                        method: 'put',
                        body: blob,
                    }
                ) 
            }
            
        } catch(e) {
            console.log("THERE WAS AN ERROR")
            console.log(e)
        }
    }

    return (
        <div className='recording-session'>
            <h2>Recording Session</h2>
            <RecordingSessionNavigation 
                GoToNextVideo={GoToNextVideo}
                GoToPreviousVideo={GoToPreviousVideo} 
                currentVideoIndex={currentVideoIndex} 
                amountOfVideos={instructionalVideoPaths.length}
            /> 
            <div className='wrapper'>
                <div className='item1'>
                    <InstructionalVideo 
                        videoPath={instructionalVideoPaths[currentVideoIndex]}
                    />
                </div>
                {DisplayRecorderOrRecorded()}
            </div>
            {DisplayUploadButton()}
        </div>
    );
}

export default RecordingSession;